import { resetPassword } from "@/api/account";
import regexHolder from "@/const/string-validation-regex";
import { Vue, Component } from "vue-property-decorator";
import IResetPasswordCredentials from "@/contracts/IResetPasswordCredentials";
import AuthPage from "@/components/Authorization/AuthPage";

import "./ResetPasswordView.scss";

@Component({
    components: {
        AuthPage
    }
})
export default class ResetPasswordView extends Vue {
    render() {
        return (
            <auth-page panelWidth="400px" header={this.$t("resetpassword.title")}>
                <el-form
                    rules={this.rulesMain()}
                    ref="resetPasswordForm"
                    validate-on-rule-change={false}
                    attrs={{ model: this.credentials }}
                    status-icon
                >
                    <el-form-item prop="username" label={this.$t("resetpassword.username")} class="mb-4">
                        <el-input
                            placeholder={this.$t("resetpassword.username")}
                            vModel={this.credentials.UserName}
                            type="username"
                        />
                    </el-form-item>
                    <el-form-item prop="email" label={this.$t("resetpassword.email")} class="mb-4">
                        <el-input
                            placeholder={this.$t("resetpassword.email")}
                            vModel={this.credentials.Email}
                            type="email"
                            maxlength="50"
                        />
                    </el-form-item>
                    <el-form-item>
                        <div class="d-flex justify-content-between">
                            <el-button
                                class="create-account-button"
                                onClick={() => {
                                    this.$router.push("/login");
                                }}
                                type="text"
                            >
                                {this.$t("default.cancel")}
                            </el-button>
                            <el-button type="primary" onClick={() => this.submit("resetPasswordForm")}>
                                {this.$t("resetpassword.resetPassword")}
                            </el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </auth-page>
        );
    }

    credentials: IResetPasswordCredentials = {
        UserName: "",
        Email: ""
    };
    error: string = "";
    regexHolder: any = regexHolder;

    async submit(formName: any) {
        try {
            let form: any = this.$refs[formName];
            await form.validate();

            await resetPassword(this.credentials);
            this.$router.push("/login");
        } catch (error) {}
    }

    rulesMain() {
        return {
            UserName: [
                {
                    required: true,
                    message: this.$t("resetpassword.enterusername")
                },
                {
                    max: 50,
                    message: this.$t("default.maxlength", { length: 50 }),
                    trigger: "blur"
                },
                {
                    pattern: this.regexHolder["settings_username"].value,
                    message: this.$t("default.invalid"),
                    trigger: "blur"
                }
            ],
            Email: [
                {
                    required: true,
                    message: this.$t("register.enteremail")
                },
                {
                    max: 100,
                    message: this.$t("default.maxlength", { length: 100 }),
                    trigger: "blur"
                },
                {
                    type: "email",
                    message: this.$t("register.entervalidemail")
                }
            ]
        };
    }
}
