export default {
    settings_name: {
        value: /^[❤️⚡⭐☔а-яА-ЯЁёA-Za-z0-9_.,\-№/ ]+$/
    },
    settings_comment: {
        value: /^[❤️⚡⭐☔а-яА-ЯёЁA-Za-z0-9_.,"'»«\-\n()\u2013\u2014[\] ]+$/
    },
    remove_emoji: {
        // eslint-disable-next-line no-control-regex
        value: /[^\u0000-\u007F]+/g
    },
    valid_emojis: {
        value: /^[☔☕☝♈♉♊♋♌♍♎♏♐♑♒♓⛎⚡⚓⛄⛅⛔⛪⛲⛳⛵⛹⛺⛽❤️❤✅❎✊✋✌✍✨❌⭕❓❗➿⭐⏳⌛⌚⏰⏩⏪⏫⏬]+$/
    },
    settings_phone: {
        value: /^\+?\d{1,3}?[\s- ]?(\(\d{1,4}\)|\d{1,3})[\s- ]?\d{1,3}[\s- ]?\d{1,2}[\s- ]?\d{1,2}$/g
    },
    settings_organization: {
        value: /^[❤️⚡⭐☔а-яА-ЯёЁA-Za-z0-9_.,\-№/"'»« ]+$/g
    },
    settings_username: {
        value: /^[A-Za-z0-9_]+$/g
    },
    transport_card_fields: {
        value: /^[❤️❤✅❎☔☕♈♉♊♋♌♍♎♏♐♑♒♓⛎⚡⚓⛄⛔⛽✍❌⭕❓❗⭐⏳⌛⏰⏩⏪⏫⏬а-яА-ЯЁёA-Za-z0-9_.,\-№/ ]+$/
    },
    negative_transport_card_fields: {
        value: /[^❤️❤✅❎☔☕♈♉♊♋♌♍♎♏♐♑♒♓⛎⚡⚓⛄⛔⛽✍❌⭕❓❗⭐⏳⌛⏰⏩⏪⏫⏬а-яА-ЯЁёA-Za-z0-9_.,\-№/ ]/g
    }
};
