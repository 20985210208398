export default [
    {
        value: -12 * 60,
        key: "gmtminus12"
    },
    {
        value: -10 * 60,
        key: "gmtminus10"
    },
    {
        value: -9 * 60,
        key: "gmtminus9"
    },
    {
        value: -8 * 60,
        key: "gmtminus8"
    },
    {
        value: -7 * 60,
        key: "gmtminus7"
    },
    {
        value: -6 * 60,
        key: "gmtminus6"
    },
    {
        value: -5 * 60,
        key: "gmtminus5"
    },
    {
        value: -4 * 60,
        key: "gmtminus4"
    },
    {
        value: -3 * 60,
        key: "gmtminus3"
    },
    {
        value: -2 * 60,
        key: "gmtminus2"
    },
    {
        value: -1 * 60,
        key: "gmtminus1"
    },
    {
        value: 0 * 60,
        key: "gmt0"
    },
    {
        value: 1 * 60,
        key: "gmt1"
    },
    {
        value: 2 * 60,
        key: "gmt2"
    },
    {
        value: 3 * 60,
        key: "gmt3"
    },
    {
        value: 4 * 60,
        key: "gmt4"
    },
    {
        value: 5 * 60,
        key: "gmt5"
    },
    {
        value: 6 * 60,
        key: "gmt6"
    },
    {
        value: 7 * 60,
        key: "gmt7"
    },
    {
        value: 8 * 60,
        key: "gmt8"
    },
    {
        value: 9 * 60,
        key: "gmt9"
    },
    {
        value: 10 * 60,
        key: "gmt10"
    },
    {
        value: 11 * 60,
        key: "gmt11"
    },
    {
        value: 12 * 60,
        key: "gmt12"
    },
    {
        value: 13 * 60,
        key: "gmt13"
    },
    {
        value: 14 * 60,
        key: "gmt14"
    }
];
