import { Component, Vue, Prop } from "vue-property-decorator";
import { VNode } from "vue";
import LocaleSelect from "@/components/Authorization/LocaleSelect";
import "./AuthPage.scss";

@Component({
    components: {
        LocaleSelect
    }
})
export default class AuthPage extends Vue {
    @Prop()
    header!: string;

    @Prop()
    panelWidth!: string;

    public render(): VNode {
        return (
            <div class="auth-page">
                <div class="auth-page__logo">
                    <img src="/static/Logo.png" height="40" alt="" />
                </div>
                <div class="auth-page__body" style={{ maxWidth: this.panelWidth }}>
                    <div class="auth-page__panel">
                        {this.header && <h4 class="auth-page__panel-header">{this.header}</h4>}
                        {this.$slots.default}
                    </div>
                </div>
                <div class="auth-page__locale">
                    <locale-select />
                </div>
            </div>
        );
    }
}
