import { Vue, Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import ILoginCredentials from "@/contracts/ILoginCredentials";
import AuthPage from "@/components/Authorization/AuthPage";
import "./LoginView.scss";
import { AxiosError } from "axios";

enum AuthError {
    WrongCredentials,
    AccountBlocked,
    AccountNotActivated
}

@Component({
    components: {
        AuthPage
    }
})
export default class LoginView extends Vue {
    render() {
        return (
            <auth-page panelWidth="400px" header={this.$t("login.title")}>
                <el-form
                    attrs={{ model: this.credentials }}
                    rules={this.rules()}
                    validate-on-rule-change={false}
                    nativeOnSubmit={this.submit}
                    ref="loginForm"
                    status-icon
                >
                    <el-form-item prop="UserName" class="mb-4">
                        <el-input
                            placeholder={this.$t("login.username")}
                            type="username"
                            vModel={this.credentials.UserName}
                        />
                    </el-form-item>
                    <el-form-item prop="Password" class="mb-1">
                        <el-input
                            placeholder={this.$t("login.password")}
                            type="password"
                            vModel={this.credentials.Password}
                        />
                    </el-form-item>
                    <el-button onClick={() => this.$router.push("/resetpassword")} class="d-flex p-0 mb-4" type="text">
                        {this.$t("login.resetpassword")}
                    </el-button>
                    <div class="d-flex mb-4">
                        <el-button onClick={this.demo} class="lo-auth-view__button" size="medium">
                            {this.$t("login.demo")}
                        </el-button>
                        <el-button
                            native-type="submit"
                            loading={this.loading}
                            class="lo-auth-view__button"
                            onClick={this.submit}
                            type="primary"
                        >
                            {this.$t("login.login")}
                        </el-button>
                    </div>
                </el-form>
                <div class="d-flex flex-column">
                    <el-button
                        onClick={() => this.$router.push("/register")}
                        class="m-auto create-account-button"
                        type="text"
                    >
                        {this.$t("login.register")}
                    </el-button>
                </div>
            </auth-page>
        );
    }

    loading: boolean = false;
    credentials: ILoginCredentials = {
        UserName: "",
        Password: ""
    };
    error: string = "";

    @Action("login") login!: any;

    showError(value: any) {
        this.$message({
            showClose: true,
            message: value,
            iconClass: "fad fa-exclamation-circle fa-lg mr-3 color-white",
            customClass: "el-message--error"
        });
    }

    handleError(error: AxiosError) {
        if (error.response) {
            if (error.response.status === 401) {
                if (error.response.data === AuthError[AuthError.WrongCredentials]) {
                    this.showError(this.$t("login.wrongcredentials"));
                } else if (error.response.data === AuthError[AuthError.AccountBlocked]) {
                    this.showError(this.$t("login.blocked"));
                } else if (error.response.data === AuthError[AuthError.AccountNotActivated]) {
                    this.showError(this.$t("login.emailnotconfirmed"));
                }
            }
            if (error.response.status === 500) {
                this.showError(this.$t("login.internalerror"));
            }
        } else {
            this.showError(this.$t("login.serverunavailable"));
        }
    }

    async demo() {
        var credentials: ILoginCredentials = {
            UserName: "Demo",
            Password: "Demo"
        };
        this.loading = true;

        try {
            await this.login(credentials);
            this.$router.push("/");
        } catch (error) {
            this.handleError(error);
        } finally {
            this.loading = false;
        }
    }

    async submit(e) {
        console.log(e);
        try {
            let form: any = this.$refs["loginForm"];
            await form.validate();
            this.loading = true;
            // Для того чтобы использовать кириллицу в логине и пароле
            // https://developer.mozilla.org/ru/docs/Web/API/WindowBase64/btoa
            let credentials = { ...this.credentials };
            credentials.Password = credentials.Password.trim();
            // credentials.UserName = unescape(encodeURIComponent(credentials.UserName));
            // credentials.Password = unescape(encodeURIComponent(credentials.Password));
            await this.login(credentials);
            this.$router.push("/");
        } catch (error) {
            if (typeof error !== "boolean") {
                this.handleError(error);
            }
        } finally {
            this.loading = false;
        }
    }

    rules() {
        return {
            UserName: [
                {
                    required: true,
                    message: this.$t("register.enterusername"),
                    trigger: "change"
                }
            ],
            Password: [
                {
                    required: true,
                    message: this.$t("register.enterpassword"),
                    trigger: "change"
                }
            ]
        };
    }
}
