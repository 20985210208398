import { Component, Vue } from "vue-property-decorator";
import { localizations } from "@/const/enums";
import { VNode } from "vue";
import "./LocaleSelect.scss";

@Component
export default class LocaleSelect extends Vue {
    created() {
        if (!localStorage.getItem("locale")) {
            localStorage.setItem("locale", localizations[0].key);
        }
    }

    handleChangeLocalization(locale: any) {
        localStorage.setItem("locale", localizations[locale].key);
        this.$i18n.locale = localizations[locale].key;
    }

    public render(): VNode {
        return (
            <div>
                {localizations.map(localization => (
                    <el-button
                        onClick={() => this.handleChangeLocalization(localization.value)}
                        class="localization-button text-muted"
                        size="medium"
                        type="text"
                    >
                        <span class="mr-2">{localization.label}</span>
                        <img
                            src={"/static/flags/" + localization.key + ".svg"}
                            height="14"
                            style="border-radius: 4px"
                        />
                    </el-button>
                ))}
            </div>
        );
    }
}
