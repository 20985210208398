import { checkUsername, register } from "@/api/account";
import timezoneOffsets from "@/const/timezone-offsets";
import regexHolder from "@/const/string-validation-regex";
import { Vue, Component } from "vue-property-decorator";
import AuthPage from "@/components/Authorization/AuthPage";
import "./RegisterView.scss";
import IRegisterForm from "@/contracts/IRegisterForm";

@Component({
    components: {
        AuthPage
    }
})
export default class RegisterView extends Vue {
    getMainForm() {
        const timezonesSelect = timezoneOffsets.map(timezone => (
            <el-option key={timezone.value} label={this.$t("enums.timezones." + timezone.key)} value={timezone.value} />
        ));

        return (
            <div key="main">
                <el-form
                    autocomplete="off"
                    attrs={{ model: this.registerForm }}
                    rules={this.rulesMain()}
                    validate-on-rule-change={false}
                    ref="registerForm"
                >
                    <el-form-item label={this.$t("register.username")} prop="username" class="mb-4">
                        <el-input
                            autocomplete="off"
                            maxlength={50}
                            type="username"
                            vModel={this.registerForm.username}
                        />
                    </el-form-item>
                    <el-form-item label={this.$t("register.email")} prop="email" class="mb-4">
                        <el-input maxlength={100} type="email" vModel={this.registerForm.email} />
                    </el-form-item>
                    <el-form-item label={this.$t("register.password")} prop="password" class="mb-4">
                        <el-input
                            autocomplete="new-password"
                            type={this.showPassword ? "text" : "password"}
                            vModel={this.registerForm.password}
                        >
                            <el-button
                                icon={this.showPassword ? "fa fa-eye fa-lg" : "fa fa-eye-slash fa-lg"}
                                onClick={() => (this.showPassword = !this.showPassword)}
                                slot="append"
                                style="outline: none; color: #152935;"
                            />
                        </el-input>
                    </el-form-item>
                    <el-form-item label={this.$t("register.timezone")} prop="timezone" class="mb-4">
                        <el-select
                            placeholder={this.$t("register.timezone")}
                            filterable
                            vModel={this.registerForm.timezoneoffset}
                        >
                            {timezonesSelect}
                        </el-select>
                    </el-form-item>
                    <div class="d-flex justify-content-between">
                        <el-button
                            onClick={() => {
                                this.$router.push("/login");
                            }}
                            type="text"
                            class="create-account-button"
                        >
                            {this.$t("register.cancel")}
                        </el-button>
                        <el-button onClick={this.submit} type="primary">
                            {this.$t("register.createaccount")}
                        </el-button>
                    </div>
                </el-form>
            </div>
        );
    }

    getResultView() {
        let result: any = null;
        if (!this.loading && !this.error) {
            result = (
                <div class="d-flex flex-column align-items-center">
                    <i class="fad fa-check-circle mb-4 register-view__success"></i>
                    <h4 class="register-view__panel-header ">{this.$t("register.success")}</h4>
                    <h6 class="mb-4">{this.$t("register.successtext")}</h6>
                    <el-button onClick={() => this.$router.push("/login")} type="primary">
                        {this.$t("register.toauth")}
                    </el-button>
                </div>
            );
        } else if (!this.loading && this.error) {
            result = (
                <div class="d-flex flex-column align-items-center">
                    <i class="fad fa-exclamation-circle mb-4 register-view__fail"></i>
                    <h4 class="register-view__panel-header ">{this.$t("register.fail")}</h4>
                    <div class="mb-4">{this.$t("register.failtext")}</div>
                    <el-button onClick={() => this.$router.push("/login")} size="medium" type="primary">
                        {this.$t("register.toauth")}
                    </el-button>
                </div>
            );
        }

        return (
            <div
                style="min-height:110px"
                element-loading-text={this.$t("register.datasending")}
                v-loading={this.loading}
            >
                {result}
            </div>
        );
    }

    render() {
        return (
            <auth-page panelWidth="500px" header={this.active !== "result" ? this.$t("register.title") : null}>
                {this.active === "main" ? this.getMainForm() : this.active === "result" ? this.getResultView() : null}
            </auth-page>
        );
    }

    loading: boolean = false;
    active: "main" | "result" = "main";
    registerForm: IRegisterForm = {
        username: "",
        password: "",
        email: "",
        name: "",
        legalentity: false,
        phone: "",
        country: "RU",
        timezoneoffset: 180,
        organization: ""
    };
    value: string = "";
    error: string | null = null;
    showPassword: boolean = false;

    checkUserName(rule: any, value: any, callback: any) {
        checkUsername(value).then(response => {
            if (response.data) {
                callback();
            } else {
                callback(new Error(this.$t("register.usernametaken").toString()));
            }
        });
    }

    async signup() {
        this.registerForm.password = this.registerForm.password.trim();
        var data = {
            ...this.registerForm
        };
        try {
            await register(data);
            this.error = null;
        } catch (error) {
            if (error.response) {
                this.error = error.response.statusText;
                return;
            }
            this.error = "Неизвестная ошибка";
        } finally {
            this.loading = false;
        }
    }

    async submit() {
        try {
            let form: any = this.$refs["registerForm"];
            await form.validate();
            this.loading = true;
            this.active = "result";
            this.signup();
        } catch (error) {
            console.error(error);
        }
    }

    rulesMain() {
        return {
            username: [
                {
                    required: true,
                    message: this.$t("register.enterusername"),
                    trigger: "change"
                },
                {
                    max: 50,
                    message: this.$t("default.maxlength", { length: 50 }),
                    trigger: "change"
                },
                {
                    pattern: regexHolder["settings_username"].value,
                    message: this.$t("default.invalid"),
                    trigger: "change"
                },
                {
                    validator: this.checkUserName,
                    message: this.$t("register.usernamenotfree"),
                    trigger: "change"
                }
            ],
            email: [
                {
                    required: true,
                    message: this.$t("register.enteremail"),
                    trigger: "change"
                },
                {
                    max: 100,
                    message: this.$t("default.maxlength", { length: 100 }),
                    trigger: "change"
                },
                {
                    type: "email",
                    message: this.$t("register.entervalidemail"),
                    trigger: "change"
                }
            ],
            password: [
                {
                    required: true,
                    message: this.$t("register.enterpassword"),
                    trigger: "change"
                },
                {
                    min: 6,
                    message: this.$t("register.passwordlength"),
                    trigger: "change"
                },
                {
                    max: 257,
                    message: this.$t("default.maxlength", { length: 257 }),
                    trigger: "change"
                }
            ]
        };
    }
}
